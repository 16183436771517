import { graphql } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import { SignatureMobile } from '../components/molecules/SignatureMobile';
import HeaderProjectPage from '../components/organisms/HeaderProjectPage';
import PageContent from '../components/organisms/PageContent';
import SEO from '../components/seo';
import { getSummary, WpPost } from '../shared/wp-post-utils';

export type WpProject = WpPost & {
  projectAttributes: {
      category: string,
      client: string,
      competences: string,
      projectEffects: string,
      technology: string,
  }
}

type ProjectPageProps = {
	data: {
		wpPost: WpProject;
  }
}

const StyledProjectPage = styled.div`
    padding: ${({ theme }) => theme.paddingDesktop}; 
    padding-top: 150px;

    @media (min-width: 1200px) and (max-width: 1600px) {
        padding: ${({ theme }) => theme.paddingLaptop}; 
        padding-top: 150px;
    }

    @media (min-width: 992px) and (max-width: 1199.98px) { 
        padding: ${({ theme }) => theme.paddingLaptop}; 
        padding-top: 120px;
    }
    
    @media (min-width: 768px) and (max-width: 991.98px) {
        padding: ${({ theme }) => theme.paddingTablet};
        padding-top: 100px;
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        padding: ${({ theme }) => theme.paddingPhone}; 
    }
`;

const ProjectEffects = styled.h4`
    margin: 0;
    padding: 100px 0 32px 0;
    font-size: 36px;
    font-weight: 600;
    color: ${({ theme }) => theme.primaryColor};
`;

const StyledContent = styled.div`
    padding: 0 100px; 
    padding-top: 80px;
    padding-bottom: 40px;
    width: 70%;

    @media (min-width: 1200px) and (max-width: 1600px) {
        padding: 70px 90px; 
        width: 80%;
    }

    @media (min-width: 992px) and (max-width: 1199.98px) { 
        padding: 50px 50px; 
        width: 90%;
    }
    
    @media (min-width: 768px) and (max-width: 991.98px) {
        padding: 40px 0;
        width: 100%;
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        padding: 40px 0; 
        width: 100%;
    }

    h1, h2, h3, a {
        color: ${({ theme }) => theme.primaryColor};
    }

    a {
        text-decoration: none;
    }
`;


const ProjectPage: React.FC<ProjectPageProps> = ({ data }) => {
    const { content, projectAttributes } = data.wpPost;
    const LDAPHeaderUser = JSON.parse(data.wpPost.author.node.ldapUser)[0];

    return ( 
        <Layout>
            <SEO title={data.wpPost.title} description={getSummary(data.wpPost)} image={data.wpPost.featuredImage.node.localFile.childImageSharp.fixed.src}/>
            <StyledProjectPage>
                <HeaderProjectPage data={data}/>
                <PageContent>
                    <SignatureMobile user={data.wpPost.author.node} author={LDAPHeaderUser.name} link={"/"}  
                        phoneNumber={LDAPHeaderUser.mobile} email={LDAPHeaderUser.mail}/>
                    <StyledContent>
                        <ProjectEffects>Opis projektu:</ProjectEffects>
                        <p>{projectAttributes.projectEffects}</p>
                    </StyledContent>
                    <StyledContent dangerouslySetInnerHTML={{ __html: content}}></StyledContent>
                </PageContent>
            </StyledProjectPage>
        </Layout>
     );
}
 
export default ProjectPage;


export const query = graphql`
query queryProject($contentId: Int!, $language: String!) {
  wpPost(databaseId: {eq: $contentId}) {
    title
    content
    dateGmt
    ...WpPostFeaturedImageOriginal
    ...NewsImageMedium
    tags {
      nodes {
        name
      }
    }
    ...AuthorPost
    ...ProjectAttributes
  }
  locales: allLocale(filter: {language: {eq: $language}}) {
        edges {
            node {
            ns
            data
            language
            }
        }
    }
}
`;