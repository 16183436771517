import innertext from "innertext";
import { makePostUrl } from "../../wp-post-utils";
import { WpMediaItem } from "./wp-media-item-utils";
import { WpUser } from "./wp-user-utils";
import { FixedObject } from "gatsby-image";
import { ImageSharp } from "./image-sharp-utils";

type WpPostInfo = {
    databaseId: number;
    language: { slug: string };
    translations: WpPostInfo[];
}

export type WpPost = WpPostInfo & {
    title: string;
    content: string;
    dateGmt: string;
    categories: {
        nodes: {
            name: string;
        }[];
    }
    tags: {
        nodes: {
            name: string;
        }[];
    }
    featuredImage: {
        node: WpMediaItem;
    }
    author: {
        node: WpUser;
    }
}

export type Post = {
    url: string;
    image: FixedObject;
    date: string;
    title: string;
    content: string;
    summary: string;
    category?: string;
}

export function getSummary(wpPost: WpPost): string {
    const longContent = innertext(wpPost.content);
    return longContent.length > 160
        ? longContent.slice(0, 160).split(' ').slice(0, -1).join(' ') + "..."
        : longContent;
}

export function getPostModel(wpPost: WpPost): Post {
    return {
        url: makePostUrl(wpPost, "aktualnosci"),
        image: wpPost.featuredImage?.node.localFile.childImageSharp.fixed,
        title: wpPost.title,
        content: wpPost.content,
        summary: getSummary(wpPost),
        category: wpPost.categories?.nodes[0].name,
        date: wpPost.dateGmt
    };
}
