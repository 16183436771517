import * as React from 'react';
import styled from 'styled-components';
import BackgroundStripes from "../../images/stripes.svg";

const StyledPageContent = styled.div`
    background-image: url(${BackgroundStripes});
    background-size: contain;
    background-position-x: center;
    width: 100%;
`;


const PageContent: React.FC = ({children}) => {
    return ( 
        <StyledPageContent>
            {children}
        </StyledPageContent>
     );
}
 
export default PageContent;