import * as React from 'react';
import styled from 'styled-components';
import { HeaderNavigation } from '../../components/atoms/HeaderNavigation';
import { SignatureHeader } from '../../components/molecules/SignatureHeader';
import { WpProject } from '../../layouts/ProjectPage';
import Img from "gatsby-image";


type HeaderProjectPageProps = {
    data: {
		wpPost: WpProject;
    }
}

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .headerProject__image {
        max-width: 40%;
        border-radius: 16px;
    }

    
    @media (min-width: 992px) and (max-width: 1600px) {
        .headerProject__image {
            max-height: 400px;
        }
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        display: block;
        .headerProject__image {
            max-width: 100%;
            margin-top: 40px;
            max-height: 350px;
        }
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        display: block;
        .headerProject__image {
            max-width: 100%;
            max-height: 350px;
            margin-top: 30px;
        }
    }
`;

const TextHeader = styled.div`
    flex-basis: 50%;
`;

const Title = styled.h1`
    margin: 0;
    padding: 38px 0 55px 0;
    font-size: 46px;
    line-height: 1.2;
    color: ${({ theme }) => theme.primaryColor};

    @media (min-width: 992px) and (max-width: 1600px) {
        font-size: 40px;
        margin: 0;
    }


    @media (min-width: 768px) and (max-width: 991.98px) {
        font-size: 40px;
        margin: 0;
    }

    @media (min-width: 321px) and (max-width: 767.98px) {
        margin: 0;
        line-height: 1.1;
        font-size: 38px;
    }

    @media (min-width: 1px) and (max-width: 320.98px) {
        margin: 0;
        font-size: 30px;
    }
`;

const StyledP = styled.p`
    margin: 0;
    padding: 0 0 8px 0;
`;

const Client = styled.p`
    margin: 0;
    padding: 0 0 55px 0;
    font-size: 24px;
    font-weight: 400;
`;

const StyledMobileHeader = styled.div`

    @media (min-width: 1px) and (max-width: 767.98px) {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
`;


const TagsWrapper = styled.div`
    display: flex;
    
    @media (min-width: 992px) and (max-width: 1500.98px) { 
        flex-direction: column;
        flex-wrap: wrap;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        flex-direction: column;
        flex-wrap: wrap;
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        flex-direction: column;
        flex-wrap: wrap;
    }
`;

const TagWrapper = styled.div`
    display: flex;
    margin-right: 48px;
`;

const StyledSignatureHeader = styled.div`
    padding-top: 55px;
    padding-bottom: 5px;
`;

const TagTitle = styled.p`
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 5px;
`;



const Tag = styled.span`
    display: inline-block;
    padding: 2px 6px;
    margin-right: 16px;
    margin-bottom: 15px;
    border-radius: 8px;
    width: fit-content;
    background-color: ${({ theme }) => theme.tagColor};
    color: ${({ theme }) => theme.primaryColor};
    border: 1px solid  ${({ theme }) => theme.primaryColor};
    font-size: 12px;
    letter-spacing: 0.2px;
    font-weight: 400;
    white-space: nowrap;
    font-family: Museo sans;
`;


 
const HeaderProjectPage: React.FC<HeaderProjectPageProps> = ({data}) => {
    const { title, tags, featuredImage, projectAttributes } = data.wpPost;
    const { category, client, competences, technology } = projectAttributes;
    const LDAPHeaderUser = JSON.parse(data.wpPost.author.node.ldapUser)[0];

    const categoryArray = category?.split(',');
    const technologyArray = technology?.split(',');
    const competenceArray = competences?.split(',');

    const createTags = tags.nodes.map(tag => <Tag key={tag.name}>{tag.name}</Tag>)

    return ( 
        <Header>
                    <TextHeader>
                        <StyledMobileHeader>
                            <HeaderNavigation isArrowVisible={false} firstLink="aktualnosci" firstLinkName="Aktualności"/>
                            <Title>{title}</Title>
                        </StyledMobileHeader>
                        <StyledP>Klient:</StyledP>
                        <Client>{client}</Client>

                        <TagsWrapper>
                            {category && 
                                <div>
                                    <TagTitle>Kategoria</TagTitle>
                                    <TagWrapper>{categoryArray?.map(item => <Tag key={item}>{item}</Tag>)}</TagWrapper>
                                </div>
                            }

                            {technology && 
                                <div>
                                    <TagTitle>Technologie</TagTitle>
                                    <TagWrapper>{technologyArray?.map(item => <Tag key={item}>{item}</Tag>)}</TagWrapper>
                                </div>
                            }

                            {competences && 
                                <div>
                                    <TagTitle>Kompetencje</TagTitle>
                                    <TagWrapper>{competenceArray?.map(item => <Tag key={item}>{item}</Tag>)}</TagWrapper>
                                </div>
                            }
                        </TagsWrapper>
                        {createTags.length > 0 && createTags}

                        <StyledSignatureHeader>
                            <SignatureHeader isStatic={true} user={data.wpPost.author.node} author={LDAPHeaderUser.name} link={"/"} 
                            phoneNumber={LDAPHeaderUser.mobile} email={LDAPHeaderUser.mail}/>
                        </StyledSignatureHeader>

                    </TextHeader>

                    <Img className="headerProject__image" fixed={featuredImage.node.localFile.childImageSharp.fixed} alt={featuredImage.node.altText} loading="eager"/>
                </Header>
     );
}
 
export default HeaderProjectPage;